//
//== DEFAULTS RAILS
  import Rails from "@rails/ujs"
  Rails.start()

//
//== MOBILE MENUE
  import 'mmenu-js/dist/mmenu'
  document.addEventListener(
    "DOMContentLoaded", () => {
      new Mmenu( "#mobilemenu", {
        "setSelected": {
          "parent": true
        },
        "navbar": {
          "title": "Menü"
        },
      });
    }
  );

//
//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

//
//== FANCYBOX
  import { Fancybox } from "@fancyapps/ui";

//
//== BOOTSTRAP
  import { Popper } from '@popperjs/core'
  import * as bootstrap from 'bootstrap'